<!-- front/MobileCart.client.vue-->

<template>
  <div v-if="cartStore.cart.length > 0" class="cart-container">
    <div class="reveal-icon" @click="showCartItemsTrigger">
      <Icon :name="showCartItemsTriggerIcon"></Icon>
    </div>

    <SharedLoading v-if="apiReq" />

    <div>
      <div v-if="showCartItems" class="cart-items">
        <h3>{{ $t('cart') }}</h3>
        <div v-for="item in cartStore.cart" :key="item.cartItemId">
          <div class="cart-item">
            <p>
              <strong>
                <NuxtLink :to="`/event/${item.slug}`">{{
                  item.title
                }}</NuxtLink>
              </strong>
            </p>
            <p class="small">
              {{ item.seatId }} / {{ item.price }}{{ currencySymbol }}
            </p>
            <span
              class="remove-from-cart"
              @click="
                releaseItemRemoveFromCart(
                  item.cartItemId.toString(),
                  item.eventId.toString(),
                  item.slug,
                  item.seatId,
                  item.holdToken
                )
              "
              >x</span
            >
          </div>
        </div>
        <a
          href="#"
          class="danger-button"
          @click="clearCart()"
          rel="noopener noreferrer"
          >{{ $t('clearCart') }}</a
        >
        <hr class="gray" />
        <p class="small">
          {{ $t('cartFeeTotal') }}: {{ cartStore.cartFeeTotal
          }}{{ currencySymbol }}
        </p>
        <p class="small">
          {{ $t('taxFeeLabel') }}: {{ cartStore.cartTaxTotal
          }}{{ currencySymbol }}
        </p>
      </div>

      <div class="flex">
        <p>x {{ cartStore.cart.length }}</p>
        <p>
          <strong
            >{{ $t('cartTotal') }}: {{ cartStore.cartTotalPrice
            }}{{ currencySymbol }}</strong
          >
        </p>
        <NuxtLink
          to="/checkout"
          class="primary-button"
          rel="noopener noreferrer"
          >{{ $t('checkout') }}
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IOrderItemCart } from '@/types'

const apiReq = ref(false)
const { t: $t } = useI18n()
const cart = useSessionStorage<IOrderItemCart[]>('cart', [])

const cartStore = useCartStore()
const seatsioStore = useSeatsioStore()
const { currentRoute } = useRouter()
const runtimeConfig = useRuntimeConfig()
const currentEventSlug = ref(currentRoute.value.params.slug)
const showCartItems = ref(false)

const siteCurrency = runtimeConfig.public.siteCurrency
const currencySymbol =
  siteCurrency === 'eur' ? '€' : siteCurrency === 'usd' ? '$' : 'rsd'

// When user clicks remove from cart. This action will alo trigger onObjectDeselected
const releaseItemRemoveFromCart = async (
  cartItemId: string,
  eventId: string,
  slug: string,
  seatId: string,
  holdToken: string
) => {
  apiReq.value = true

  const release = await cartStore.releaseItemRemoveFromCart(
    cartItemId,
    eventId,
    seatId,
    holdToken
  )

  // When release from seatsio is successfull we can remove the overlay
  if (release && release.success) {
    setTimeout(() => {
      apiReq.value = false
    }, 500)
  }

  // We should remove from cart item that not belong to current event because remove from cart is done on deselectObject action
  if (currentEventSlug.value !== slug) {
    const removeIndex = cartStore.cart.findIndex(
      (item) => item.cartItemId === cartItemId
    )
    // remove object
    cartStore.cart.splice(removeIndex, 1)
  }
}

const clearCartToastMessage = computed(() => {
  return $t('clearCartMessage')
})

const showCartItemsTriggerIcon = computed(() => {
  return showCartItems.value
    ? 'dashicons:arrow-down-alt2'
    : 'dashicons:arrow-up-alt2'
})

const showCartItemsTrigger = () => {
  showCartItems.value = !showCartItems.value
}

const clearCart = async () => {
  // Clear cart immediately, because hold otken release could  fail
  setTimeout(() => {
    cartStore.cart = []
  }, 1000)

  const holdToken = sessionStorage.getItem('seatsio')
    ? JSON.parse(sessionStorage.getItem('seatsio') ?? '').holdToken
    : false

  try {
    const destroy = await seatsioStore.destroyHoldToken(
      currentEventSlug.value.toString(),
      holdToken
    )
    if (destroy && destroy.success) {
      useToast().success(clearCartToastMessage.value)
    }
  } catch (error) {
    console.error(error)
  }
}
</script>

<style scoped>
.remove-from-cart {
  color: var(--color-red);
  transition: all 0.3s;
  margin-left: 0.5em;
  margin: 0 auto;
  left: auto;
  right: 1em;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.remove-from-cart:hover {
  cursor: pointer;
  color: #999;
}

.cart-container {
  padding: 1em;
  border: 1px solid #ddd;
  margin-top: 20px;
  background-color: #f5f5f5;
  margin: 0 auto;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.cart-container .primary-button {
  margin: 0;
  padding: 1em 1.5em;
}

.cart-item {
  border: 1px solid #fff;
  background-color: #fff;
  padding: 0.5em 1em;
  margin: 0.5em 0;
  position: relative;
}

.cart-item p {
  margin: 0.3em 0;
}

.reveal-icon {
  position: absolute;
  right: auto;
  top: -18px;
  left: 2em;
  margin: 0 auto;
  text-align: center;
  display: inline-block;
  transform: translateY(-50%);
  background-color: var(--primary-color);
  padding: 0.5em;
  border-radius: 50%;
  cursor: pointer;
  width: 36px;
  border-top: 1px solid var(--gray);
  transition: transform 0.3s, background-color 0.3s;
  animation: jump 1s infinite;
}

@keyframes jump {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2px);
  }
}
</style>
